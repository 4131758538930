import React, { useState, useEffect } from 'react';
import { FaTwitter, FaTelegram, FaFileAlt, FaMoon, FaSun } from 'react-icons/fa';
import pumpkinLogo from './assets/pumpkinnewicon.png';
import aftermathLogo from './assets/aftermath-logo-white-transparent.png';
import { useTheme } from './ThemeProvider';

export default function PumpkinTokenLanding() {
  const { theme, toggleTheme } = useTheme();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`App ${theme}`}>
      <header className="App-header">
        <div className="header-top">
          <h1>Pumpkin Token</h1>
          <button onClick={toggleTheme} className="theme-toggle">
            {theme === 'light' ? <FaMoon /> : <FaSun />}
          </button>
        </div>
        <img src={pumpkinLogo} className="App-logo" alt="Pumpkin Token" />
        <div className="content-card">
          <h2>Welcome to Pumpkin Token!</h2>
          <p className="tagline">
            Pumpkin Token is a meme token that brings true utility to the SUI ecosystem 💧⚡🌴
          </p>
          <h3>The Heroic Tale of Pumpkin</h3>
          <p>
            Pumpkin the Cat, once a humble companion, saved a man's life. 
            When the man was struggling with addiction, Pumpkin scratched him, forcing him to the hospital. 
            There, doctors discovered life-threatening issues that they were able to address in time, all thanks to Pumpkin.
            Now, Pumpkin Token represents hope, courage, and redemption, just as Pumpkin did for that man.
          </p>
        </div>

        <div className="top-links">
          <a
            className="App-link primary-link"
            href="https://thepumpkintoken.org/PumpkinWhitePaper1.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFileAlt /> Whitepaper
          </a>
          <a
            className="App-link"
            href="https://dexscreener.com/sui/0x3370693cdc29080a1e90f0f927198c751338de7ef7689b505865bf16a0a30461"
            target="_blank"
            rel="noopener noreferrer"
          >
            😺 DexScreener Listing
          </a>
          <a
            className="App-link"
            href="https://dex.bluemove.net/swap/0x2::sui::SUI/0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
            target="_blank"
            rel="noopener noreferrer"
          >
            😺 BlueMove Swap
          </a>
          <a
            className="App-link"
            href="https://hop.ag/swap/SUI-0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
            target="_blank"
            rel="noopener noreferrer"
          >
            😺 Swap on Hop.ag
          </a>
          <a
            className="App-link"
            href="https://www.coingecko.com/en/coins/pumpkin-token"
            target="_blank"
            rel="noopener noreferrer"
          >
            😺 CoinGecko
          </a>
        </div>

        <div className="social-links">
          <a
            className="App-link"
            href="https://x.com/thepumpkintoken"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter /> Follow us on X (Twitter)
          </a>
          <a
            className="App-link"
            href="https://t.me/+IvaAbB-kQyc3NGQx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTelegram /> Join our Telegram
          </a>
        </div>

        <div className="contract-links">
          <a
            className="App-link"
            href="https://movepump.com/token/0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
            target="_blank"
            rel="noopener noreferrer"
          >
            😺 MovePump Contract
          </a>
        </div>
      </header>

      <div className="powered-by">
        <p>Powered by Aftermath</p>
        <a href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN" target="_blank" rel="noopener noreferrer">
          <img
            src={aftermathLogo}
            alt="Powered by Aftermath"
            className="aftermath-logo"
          />
        </a>
        <p className="swap-now">Click to swap now</p>
      </div>

      <div
        className="scroll-reveal-section"
        style={{
          opacity: Math.min(scrollY / 500, 1),
          transform: `translateY(${Math.max(100 - scrollY / 5, 0)}px)`,
        }}
      >
        <div className="reveal-container">
          <div className="reveal-box">
            <h2>SUIQuant DexAlgo™</h2>
            <div className="reveal-content">
              <p className="reveal-subtitle">Enter 🚀</p>
              <p className="reveal-description">
                A revolutionary SUI decentralized exchange algorithm developed by Quantum Void Labs.
              </p>
              <ul className="reveal-features">
                <li>Secure and autonomous trading</li>
                <li>No reliance on Telegram or external platforms</li>
                <li>Advanced security protocols</li>
                <li>Real-time market analysis</li>
              </ul>
              <a
                href="https://dexalgo.quantumvoid.org"
                target="_blank"
                rel="noopener noreferrer"
                className="reveal-button"
              >
                Launch DexAlgo
              </a>
            </div>
          </div>

          <div className="reveal-box">
            <h2>AlgoVault™</h2>
            <div className="reveal-content">
              <p className="reveal-subtitle">Secure your assets</p>
              <p className="reveal-description">
                An innovative algorithmic vault system by Quantum Void Labs.
              </p>
              <ul className="reveal-features">
                <li>DAO-powered reward system</li>
                <li>Automated yield optimization</li>
                <li>Community-driven governance</li>
                <li>Enhanced security measures</li>
              </ul>
              <a
                href="https://app.quantumvoid.org/vault"
                target="_blank"
                rel="noopener noreferrer"
                className="reveal-button"
              >
                Enter Vault
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
