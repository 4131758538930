import React from 'react';
import { ThemeProvider } from './ThemeProvider';
import PumpkinTokenLanding from './PumpkinTokenLanding';
import './App.css';

function App() {
  return (
    <ThemeProvider>
      <PumpkinTokenLanding />
    </ThemeProvider>
  );
}

export default App;

